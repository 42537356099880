.error-container {
  margin-top: 80px;
  font-family: 'Dosis', sans-serif;
  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
  img.kofik-error {
    max-width: 100%;
    @media screen and (max-width: 768px) {
      max-width: 60%;
    }
  }
  h2 {
    font-size: 48px;
    margin: 25px 0;
    font-weight: 700;
    color: #FFF;
    @media screen and (max-width: 768px) {
      font-size: 36px;
    }
  }
  p {
    font-size: 24px;
    color: #FFF;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    margin-top: 30px;
  }
}